.info-simple{
    width: 278px;
    align-items: center;
    margin: auto;
    &.card{
        width: 320px;
        padding: 35px 24px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
        border-radius: 6px;
    }
    .info-simple-title{
        color: $color-black;
        font-family: $roboto-bold;
        font-size: 24px;
    }

    .info-simple-icon{
        margin-bottom: 15px;
    }
    .info-simple-content{
        padding: 0 20px;
    }
}

.component-info{
    &:first-child{
        margin-left: 20px;
    }
}

@media screen and (max-width:768px){
    .producto{
            width: 49%;
            margin-right: 0px;
            margin-top: 0px;
            min-width: auto;
    }
}