.footer-vado{
   
    .footer-vado-start{
        padding: 45px 0px 45px 0px;
        .logo{
            width: 145px;
            height: 55px;
        }
        .footer-vado-title{
            font-size: 14px;
            font-family: $roboto-bold;
            margin-bottom: 18px;
        }
        .footer-vado-links{
            margin-top: 7px;
            text-decoration: none;
            font-size: 13px;
            color: $color-black-light;
            font-family: $roboto-regular;
        }
    }
    .footer-element-end{
        padding-right: 123px;
    }

}

@media screen and (max-width:767px){
    .footer-vado{
        .footer-vado-start{
            padding: 40px 0px;
        }
        .logo{
            margin: auto;
            margin-bottom: 40px;
        }

        .footer-vado-title{
            margin: auto;
            margin-bottom: 18px;
        }
        .footer-vado-links{
            margin: auto;
            margin-top: 7px;
        }
        .footer-element-end{
            padding-right: 0px;
            justify-content: center !important;
        }
    }
}